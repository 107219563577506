<template>
  <!--begin::App-->
  <div id="kt_app_root" class="d-flex flex-column flex-root app-root">
    <!--begin::Page-->
    <div id="kt_app_page" class="app-page flex-column flex-column-fluid">
      <LHeader></LHeader>
      <!--begin::Wrapper-->
      <div id="kt_app_wrapper" class="app-wrapper flex-column flex-row-fluid">
        <LSidebar></LSidebar>
        <!--begin::Main-->
        <div id="kt_app_main" class="app-main flex-column flex-row-fluid">
          <!--begin::Content wrapper-->
          <div class="d-flex flex-column flex-column-fluid">
            <LToolbar></LToolbar>
            <div id="kt_app_content" class="app-content flex-column-fluid">
              <LContent></LContent>
            </div>
          </div>
          <!--end::Content wrapper-->
          <LFooter></LFooter>
        </div>
        <!--end:::Main-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Page-->
  </div>
  <LScrollTop></LScrollTop>
  <LToolButtons />

  <!--end::App-->
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, onMounted, nextTick } from 'vue';

import LHeader from '@/components/theme/metronic/layout/header/Header';
import LSidebar from '@/components/theme/metronic/layout/sidebar/Sidebar';
import LToolbar from '@/components/theme/metronic/layout/toolbar/Toolbar';
import LContent from '@/components/theme/metronic/layout/content/Content';
import LFooter from '@/components/theme/metronic/layout/footer/Footer';
import LScrollTop from '@/components/theme/metronic/layout/ScrollTop.vue';
import LDrawers from '@/components/theme/metronic/layout/drawers/Drawers.vue';
import LToolButtons from '@/components/theme/metronic/layout/ToolButtons.vue';
import LayoutService from '@/theme/metronic/services/LayoutService';
import { reinitializeComponents } from '@/theme/metronic/plugins/keenthemes.ts';

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    LHeader,
    LSidebar,
    LToolbar,
    LContent,
    LFooter,
    LScrollTop,
    LDrawers,
    LToolButtons
  },
  setup() {
    onBeforeMount(() => {
      LayoutService.init();
    });

    onMounted(() => {
      nextTick(() => {
        reinitializeComponents();
      });
    });
  }
});
</script>
<style>
body {
  background-image: none;
}
</style>