<template>
  <div v-if="currentUser && currentUser.has_disable_requested && !useUtil().isPastDate(currentUser.currentSubscription.ends_at)" class="row">
    <div class="col-12 col-lg-6 mt-lg-3 mx-lg-auto">
      <DisableUserCard />
    </div>
  </div>
  <div v-if="currentUser && currentUser.has_simpatizante_requested && !useUtil().isPastDate(currentUser.currentSubscription.ends_at)" class="row">
    <div class="col-12 col-lg-6 mt-lg-3 mx-lg-auto">
      <SimpatizanteRequestUserCard />
    </div>
  </div>
  <!--begin::Toolbar-->
  <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
    <!--begin::Toolbar container-->
    <div
      id="kt_app_toolbar_container"
      class="app-container d-flex flex-stack"
      :class="{
        'container-fluid': toolbarWidthFluid,
        'container-xxl': !toolbarWidthFluid
      }"
    >
      <LToolbarPageTitle />
      <!--begin::Actions-->
<!--      <div class="d-flex align-items-center gap-2 gap-lg-3">-->
<!--        &lt;!&ndash;begin::Secondary button&ndash;&gt;-->
<!--        <a-->
<!--          href="#"-->
<!--          class="btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary"-->
<!--          data-bs-toggle="modal"-->
<!--          data-bs-target="#kt_modal_create_app"-->
<!--          >Rollover</a-->
<!--        >-->
<!--        &lt;!&ndash;end::Secondary button&ndash;&gt;-->
<!--        &lt;!&ndash;begin::Primary button&ndash;&gt;-->
<!--        <a href="#" class="btn btn-sm fw-bold btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_new_target"-->
<!--          >Add Target</a-->
<!--        >-->
<!--        &lt;!&ndash;end::Primary button&ndash;&gt;-->
<!--      </div>-->
      <!--end::Actions-->
    </div>
    <!--end::Toolbar container-->
  </div>
  <!--end::Toolbar-->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { toolbarWidthFluid } from '@/assets/theme/metronic/ts/helpers/config';
import LToolbarPageTitle from '@/components/theme/metronic/layout/toolbar/PageTitle';
import {useAuthStore} from "~/stores/auth";
import {storeToRefs} from "pinia";
import DisableUserCard from "~/components/users/cards/DisableUserCard.vue";
import SimpatizanteRequestUserCard from "~/components/users/cards/SimpatizanteRequestUserCard.vue";

export default defineComponent({
  name: 'ThemeToolbar',
  components: {
    SimpatizanteRequestUserCard,
    DisableUserCard,
    LToolbarPageTitle
  },
  setup() {
    const store = useAuthStore();
    const { currentUser } = storeToRefs(store);

    return {
      toolbarWidthFluid,
      currentUser
    };
  }
});
</script>
