<template>
  <!--begin::Navbar-->
  <div class="app-navbar flex-shrink-0">
    <!--begin::Chat-->
<!--    <div class="app-navbar-item ms-1 ms-md-3">-->
<!--      &lt;!&ndash;begin::Menu wrapper&ndash;&gt;-->
<!--      <div-->
<!--        id="kt_drawer_chat_toggle"-->
<!--        class="btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-30px h-30px w-md-40px h-md-40px position-relative"-->
<!--      >-->
<!--        <RouterLink to="/soporte/tickets/listado">-->
<!--          <i class="bi bi-chat-square-text fs-2 fs-md-1"></i>-->
<!--          <span-->
<!--              v-if="currentUser.has_open_tickets"-->
<!--              class="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink"-->
<!--          ></span>-->
<!--        </RouterLink>-->
<!--      </div>-->
<!--      &lt;!&ndash;end::Menu wrapper&ndash;&gt;-->
<!--    </div>-->
    <!--end::Chat-->
    <!--begin::User menu-->
    <div id="kt_header_user_menu_toggle" class="app-navbar-item ms-1 ms-md-3">
      <!--begin::Menu wrapper-->
      <div
        class="cursor-pointer symbol symbol-30px symbol-md-40px"
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
      >
        <span class="bg-light-danger text-primary symbol-label fw-bold">
            {{ currentUser.name.substring(0, 1) + currentUser.surname.substring(0, 1) }}
        </span>
      </div>
      <LUserMenu />
      <!--end::Menu wrapper-->
    </div>
    <!--end::User menu-->
    <!--begin::Header menu toggle-->
<!--    <div class="app-navbar-item d-lg-none ms-2 me-n3">-->
<!--      <div-->
<!--        id="kt_app_header_menu_toggle"-->
<!--        class="btn btn-icon btn-active-color-primary w-30px h-30px w-md-35px h-md-35px"-->
<!--      >-->
<!--        <i class="bi bi-rocket-takeoff fs-2 fs-md-1"></i>-->
<!--      </div>-->
<!--    </div>-->
    <!--end::Header menu toggle-->
  </div>
  <!--end::Navbar-->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { getAssetPath } from 'assets/theme/metronic/ts/helpers/assets';
import LUserMenu from '@/components/theme/metronic/layout/header/navbar/menus/UserAccountMenu.vue';
import {useAuthStore} from "~/stores/auth";
import {storeToRefs} from "pinia";

export default defineComponent({
  name: 'ThemeHeaderNavbar',
  components: {
    LUserMenu,
  },
  setup() {
    const store = useAuthStore();
    const { currentUser } = storeToRefs(store);

    return {
      currentUser,
      getAssetPath
    };
  }
});
</script>
