<template>
  <div class="card border border-warning">
    <div class="card-body d-flex align-items-center p-5">
      <!--begin::Icon-->
      <div class="d-flex h-50px w-50px h-lg-80px w-lg-80px flex-shrink-0 flex-center position-relative align-self-start align-self-lg-center mt-3 mt-lg-0">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="text-primary h-75px w-75px h-lg-100px w-lg-100px position-absolute opacity-5">
          <path fill="currentColor" d="M10.2,21.23,4.91,18.17a3.58,3.58,0,0,1-1.8-3.11V8.94a3.58,3.58,0,0,1,1.8-3.11L10.2,2.77a3.62,3.62,0,0,1,3.6,0l5.29,3.06a3.58,3.58,0,0,1,1.8,3.11v6.12a3.58,3.58,0,0,1-1.8,3.11L13.8,21.23A3.62,3.62,0,0,1,10.2,21.23Z"></path>
        </svg>
        <i class="bi bi-clock-history fs-3x text-primary position-absolute"></i>
      </div>
      <!--end::Icon-->
      <!--begin::Description-->
      <div class="ms-6">
        <p class="fs-6 p-0 m-0">
          El próximo <span class="text-primary fw-bold">{{ currentUser.currentSubscription.ends_at }}</span> tu plan pasará de
          <span class="text-primary fw-bold">{{ currentUser.currentSubscription.plan.name }}</span>
          a simpatizante.
          <br>
          ¡Gracias por seguir formando parte de ASUFIN!
        </p>
        <p class="fs-6 p-0 m-0">
          <button @click="useContact().requestCancelDisableUser()" class="btn btn-sm btn-success px-2 py-1 align-self-center fw-bold text-nowrap me-3">
            Cancelar cambio a simpatizante y mantener la suscripción actual
          </button>
        </p>
      </div>
      <!--end::Description-->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {useAuthStore} from "~/stores/auth";
import {storeToRefs} from "pinia";

export default defineComponent({
  name: 'simpatizante-request-user-card',
  components: {
  },
  setup() {
    const store = useAuthStore();
    const { currentUser } = storeToRefs(store);

    return {
      currentUser
    };
  }
});
</script>